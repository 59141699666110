import React from 'react';
import './SectionPartners.scss';
import './mobile/index.scss';
import { Container } from '@material-ui/core';
import {
  bitmain, canaan, coinpods, f2pool, kraken, poolin,
} from '@assets/img';
import { withTranslation } from '@i18n';
import Image from 'next/image';

const SectionPartners = (props: any) => {
  const {
    t,
  } = props;
  return (
    <div className="SectionPartners">
      <div className="main__partners-section">
        <Container>
          <h3 className="main-page-title">{t('Наши партнеры')}</h3>
          <div className="main__partners">
            <div>
              <img
                src={bitmain}
                alt="bitmain"
                width="175"
                height="22"
              />
            </div>
            <div>
              <Image
                src={canaan}
                alt="canaan"
                width="175"
                height="45"
              />
            </div>
            <div>
              <Image
                src={poolin}
                alt="poolin"
                width="175"
                height="40"
              />
            </div>
            <div>
              <Image
                src={f2pool}
                alt="f2pool"
                width="175"
                height="53"
              />
            </div>
            <div>
              <Image
                src={kraken}
                alt="kraken"
                width="175"
                height="28"
              />
            </div>
            <div>
              <Image
                src={coinpods}
                alt="coinpods"
                width="175"
                height="59"
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default withTranslation('common')(SectionPartners);
